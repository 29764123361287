import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import SwiperCore, { Navigation, Pagination } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { StyledContainer, Product, ProductContent } from "./styles"

SwiperCore.use([Navigation, Pagination])

const Mesas = () => {
  const data = useStaticQuery(graphql`
    query {
      mesa1: file(relativePath: { eq: "products/mesa-reta-pes-de-ferro.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mesaDec1500: file(
        relativePath: { eq: "products/mesa-office-dec-1500.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 400, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <StyledContainer as="section">
      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.mesa1.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Mesa reta pés de ferro com 2 gavetas/fechadura</h3>

          <ul>
            <li>Cód.: MesaReta2gavetas</li>
            <li>Cor: Branca</li>
            <li>Fechadura: com chave</li>
            <li>Gavetas: 2</li>
            <li>Medidas gavetas: 35 largura, 40 profundidade e 9 altura</li>
            <li>Altura mesa: 76 cm </li>
            <li>Largura: 120 cm</li>
            <li>Profundidade: 76 cm</li>
            <li>Material: Mdf 100%</li>
          </ul>

          {/* <p className="item-price">
            R$ 250,<span>00</span>
          </p>

          <small>Consulte Taxa de Entrega</small> */}
        </ProductContent>
      </Product>

      <Product>
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          pagination={{ type: "fraction" }}
          // navigation
        >
          <SwiperSlide>
            <Img fluid={data.mesaDec1500.childImageSharp.fluid} />
          </SwiperSlide>
        </Swiper>

        <ProductContent>
          <h3>Mesa Office DEC 1500</h3>

          <ul>
            <li>Cód.: 2909</li>
            <li>Cor: Carvalho Berlin</li>
            <li>Altura: 76 cm</li>
            <li>Largura: 60 cm</li>
            <li>Profundidade: 150 cm</li>
            <li>Espessura: 3 cm</li>
            <li>Tampo em MDF com borda dupla</li>
            <li>Estrutura metálica com pintura a pó eletrostática</li>
            <li>Pés niveladores metálicos com regulagem de altura</li>
            <li>Cores disponíveis: branco, preto, cinza e amadeirado</li>
          </ul>

          <p className="item-price">Consulte</p>

          <small>Consulte Taxa de Entrega</small>
        </ProductContent>
      </Product>
    </StyledContainer>
  )
}

export default Mesas
